import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "d-flex flex-row w-100" }
const _hoisted_3 = { class: "m-2 p-1 view-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_spinner = _resolveComponent("loading-spinner")!
  const _component_banner_component = _resolveComponent("banner-component")!
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_component = _resolveComponent("footer-component")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_loading_spinner, {
      "show-spinner": _ctx.pageLoading,
      "show-blur": _ctx.pageLoadingBlur
    }, null, 8, ["show-spinner", "show-blur"]),
    _createVNode(_component_banner_component, { name: "Jason Rice" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_nav_bar)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view)
      ])
    ]),
    _createVNode(_component_footer_component, {
      version: _ctx.version || undefined
    }, null, 8, ["version"])
  ]))
}