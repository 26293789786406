<template>
  <div class="skills-container">
    <h2>Technical Skills</h2>
    <section>
      <h3>Languages</h3>
      <p>TypeScript, JavaScript, Kotlin, Java, Python</p>
    </section>
    <section>
      <h3>Technologies</h3>
      <p>
        Vue.js, Angular, Node.js, Express.js, Spring Boot, PostgreSQL, Git,
        ClickUp, Bitbucket, GitHub, FreeMarker, LogRocket, Google Cloud Platform
        (GCP)
      </p>
    </section>
    <section>
      <h3>Concepts</h3>
      <p>
        Agile Methodology, Responsive Design, Continuous Integration/Continuous
        Deployment (CI/CD) Pipelines, Back-End for Front-End (BFF),
        Model-View-Controller (MVC), Model-View-ViewModel (MVVM), Functional
        Programming, Object-Oriented Programming (OOP), RESTful APIs, Database
        Normalization, Clean Code, Refactoring, Security, Cross-Site Request
        Forgery (CSRF), Cross-Site Scripting (XSS), Single Page Applications
        (SPA), Multi-Page Applications (MPA), Root Cause Analysis
      </p>
    </section>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped>
.skills-container {
  padding: 10px;
  text-align: start;
  section {
    padding-top: 2px;
  }
}

h2 {
  text-decoration: underline;
  text-underline-offset: 4px;
}
</style>
